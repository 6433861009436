















import { Component, Vue, Prop } from 'vue-property-decorator';
import {AdvertiserCampaignListing} from '../domain/AdvertiserCampaignListing';

export default class AdvertiserCampaignTable extends Vue {
    @Prop() private readonly campaigns: AdvertiserCampaignListing[] = [];
    @Prop() private readonly pageCount: number = 0;
    @Prop() private readonly isLoading: boolean = false;

    private async onPageChange(newPage: number) {
        this.$emit('pageChange', newPage - 1); // Lors d'un click sur la page 1, on doit retourner la page 0
    }

    private viewCampaign(campaign: AdvertiserCampaignListing) {
        this.$router.push(`/view-campaign/${campaign.id}`);
    }
}

