
















import {Component, Vue} from 'vue-property-decorator';
import Card from '@/components/card/Card.vue';
import Page from '@/components/Page.vue';
import { Advertiser } from '../domain/Advertiser';
import { Id } from '../../user/domain/Types';
import { AdvertiserService } from '../services/AdvertiserService';
import AdvertiserForm, { AdvertiserSubmitEvent } from '../components/AdvertiserForm.vue';
import AdvertiserCampaignTable from '../components/AdvertiserCampaignTable.vue';
import { AdvertiserCampaignListing } from '../domain/AdvertiserCampaignListing';
import {CampaignService} from '@/modules/campaign/services/CampaignService';
import { PortalService } from '@/modules/portal/services/PortalService';
import { PortalZone } from '@/modules/portal/domain/PortalZone';
import { Portal } from '@/modules/portal/domain/Portal';

@Component({
    components: {Card, Page, AdvertiserForm, AdvertiserCampaignTable},
})
export default class ViewAdvertiser extends Vue {
    private advertiser: Advertiser | Advertiser = {
        id: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        managerName: '',
        accountingEmail: '',
        zones: [],
    };
    private isLoading: boolean = false;
    private hasError: boolean = false;
    private advertiserId: Id = '';

    private activeCampaigns: AdvertiserCampaignListing[] = [];
    private activeCampaignsAreLoading: boolean = false;
    private activeCampaignsPageCount: number = 0;

    private inactiveCampaigns: AdvertiserCampaignListing[] = [];
    private inactiveCampaignsAreLoading: boolean = false;
    private inactiveCampaignsPageCount: number = 0;
    private portals: Portal[] | Portal[] = [];

    private async mounted() {
        this.advertiserId = this.$route.params.advertiserId;
        const currentPage = 0;

        await Promise.all([
            this.fetchActiveCampaignsFor(currentPage),
            this.fetchInactiveCampaignsFor(currentPage),
            this.fetchAdvertiserWith(this.advertiserId),
        ]);
    }

    private async fetchAdvertiserWith(id: Id) {
        this.isLoading = true;
        try {
            this.advertiser = await AdvertiserService.getAdvertiserBy(id, this.$store.getters['sessionStore/token']);
            const portals = await PortalService.getAll(this.$store.getters['sessionStore/token']);
            const zones = await PortalService.getZonesForUser(this.$store.getters['sessionStore/token']);
            await portals.forEach((e, index) => {
                portals[index].zones = zones.filter((z: PortalZone) => z.portalId === e.id);
            });
            this.portals = portals;
        } catch {
            this.hasError = true;
        }
        this.isLoading = false;
    }

    private async fetchActiveCampaignsFor(page: number) {
        this.activeCampaignsAreLoading = true;
        try {
            const { campaigns, pageCount } = await CampaignService
                .getActiveCampaignListingFor(this.advertiserId, page, this.$store.getters['sessionStore/token']);
            this.activeCampaigns = campaigns;
            this.activeCampaignsPageCount = pageCount;
        } catch {
            this.activeCampaigns = [];
        }
        this.activeCampaignsAreLoading = false;
    }

    private async fetchInactiveCampaignsFor(page: number) {
        this.activeCampaignsAreLoading = true;
        try {
            const { campaigns, pageCount } = await CampaignService
                .getInactiveCampaignListingFor(this.advertiserId, page, this.$store.getters['sessionStore/token']);
            this.inactiveCampaigns = campaigns;
            this.inactiveCampaignsPageCount = pageCount;
        } catch {
            this.inactiveCampaigns = [];
        }
        this.activeCampaignsAreLoading = false;
    }

    private async updateAdvertiser(advertiser: AdvertiserSubmitEvent) {
        this.isLoading = true;
        try {
            await AdvertiserService
                .update(advertiser, this.advertiserId, this.$store.getters['sessionStore/token']);

            this.$notify.success({title: 'SUCCÈS', message: 'Les informations ont été mises à jour !'});
        } catch {
            this.$notify.error({
                title: 'ERREUR',
                message: 'Erreur lors de la mise à jours. Veuillez vérifier vos champs et reessayer.',
            });
        }
        this.isLoading = false;
    }

    get hasAdvertiser() {
        return Boolean(this.advertiser);
    }

    get advertiserName() {
        if (!this.advertiser) {
            return '';
        }

        return this.advertiser.name;
    }
}
